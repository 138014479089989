export const socialIconLinks = {
  mainSocials: [
    {
      name: "GitHub",
      link: "https://github.com/rakshixh",
    },
  ],
  subSocials: [
    {
      name: "Email",
      link: "mailto:rakshixh.info@gmail.com",
    },
    {
      name: "LinkedIn",
      link: "https://in.linkedin.com/in/rakshixh",
    },
    {
      name: "Pinterest",
      link: "https://in.pinterest.com/rakshixh/",
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/rakshixh/",
    },
  ],
};

export const HomeLinks = {
  mainLinks: [
    {
      name: "Portfolio",
      link: "https://rakshixh.tech",
    },
    {
      name: "Code Questic",
      link: "https://github.com/CodeQuestic",
    },
    {
      name: "Buy Me A Burger",
      link: "https://buymeacoffee.com/rakshixh",
    },
  ],
  subLinks: [
    {
      name: "Link 2",
      link: "https://github.com",
    },
    {
      name: "Link 3",
      link: "https://github.com",
    },
    {
      name: "Link 4",
      link: "https://github.com",
    },
  ],
};

export const PhotoLinks = {
  mainLinks: [
    {
      name: "VSCO",
      link: "https://vs.co/se73k8m4",
    },
    {
      name: "Instagram (Photo)",
      link: "https://www.instagram.com/rakshixh.photo/",
    },
  ],
  subLinks: [
    {
      name: "Link 2",
      link: "https://github.com",
    },
    {
      name: "Link 3",
      link: "https://github.com",
    },
    {
      name: "Link 4",
      link: "https://github.com",
    },
  ],
};

export const ArtLinks = {
  mainLinks: [
    {
      name: "Artfolio",
      link: "https://rakshixh-art.vercel.app/",
    },
  ],
  subLinks: [
    {
      name: "Link 2",
      link: "https://github.com",
    },
    {
      name: "Link 3",
      link: "https://github.com",
    },
    {
      name: "Link 4",
      link: "https://github.com",
    },
  ],
};
